<template>
  <div>
    <v-container class="container px-0">
      <v-row
        class="title-row"
        :class="`${
          $vuetify.breakpoint.mdAndDown ? 'flex-column flex-nowrap' : ''
        }`"
      >
        <v-col class="d-flex align-center">
          <h4 class="font-weight-light">{{ $t('heading.hosting.cron.title') }}</h4>
          <active-server-account-selector
            v-if="service && service.server_accounts.length > 1"
            class="ml-4"
            :value.sync="serverAccount"
            :items="service.server_accounts"
          />
        </v-col>

        <v-col
          v-show="items.length"
          class="page-title__filter-controls justify-end"
          :class="[`${$vuetify.breakpoint.mobile ? 'pb-3' : ''}`]"
        >
          <data-iterator-filter-controls
            :keys="keys"
            :showSearchTerm="true"
            :searchTerm="searchTerm"
            :fullWidthSearch="false"
            :fullWidthLg="false"
            :fullWidthMd="true"
            @update:sortBy="changeSortBy"
            @update:sortDesc="handleSortDescChange"
            @update:searchTerm="handleSearchTermChange"
          ></data-iterator-filter-controls>

          <v-btn
            :large="!$vuetify.breakpoint.smAndDown"
            elevation="0"
            color="primary"
            class="p-2 add-new-button page-title__add-button"
            @click="showAddModal"
          >
            <v-icon :class="$vuetify.breakpoint.mobile ? 'mr-0' : ''">
              $plus
            </v-icon>
            {{ $vuetify.breakpoint.mobile ? "" : $t('button.cron.add') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="mt-3 px-0">
      <v-row>
        <v-col cols="12">
          <div :class="checkedItems.length > 0 ? 'pb-15' : ''">
            <hosting-table
              v-if="serverAccount && !serverAccount.diagnostic_mode"
              :checkedItems="checkedItems"
              :headers="headers"
              :items="items"
              :itemsLoading="loading"
              :itemsPerPage="itemsPerPage"
              :itemsTotal="itemsTotal"
              :page="page"
              :possibleItemsPerPage="[[8, 16, 24]]"
              :searchTerm="searchTerm"
              :sortBy="sortBy"
              :sortDesc="sortDesc"
              @clearFilters="searchTerm = ''"
              @update:check-all="handleSelectAll"
              @update:checked-items="handleItemCheck"
              @update:itemsPerPage="changePerPage"
              @update:page="changePage"
              @update:sortBy="changeSortBy"
              @update:sortDesc="changeSortDesc"
              :itemClass="itemClass"
            >
              <template v-slot:no-data>
                <TableFullScreenMessage
                  :title="$t('message.emptyTable.cron.title')"
                  :desc="$t('message.emptyTable.cron.description')"
                >
                  <template v-slot:image>
                    <hosting-cron-jobs-illustration />
                  </template>
                  <template v-slot:action>
                    <v-btn
                      :large="!$vuetify.breakpoint.smAndDown"
                      :small="$vuetify.breakpoint.smAndDown"
                      elevation="0"
                      color="primary"
                      class="p-2 add-new-button"
                      @click="showAddModal"
                    >
                      <v-icon :class="$vuetify.breakpoint.mobile ? 'mr-0' : ''"
                        >$plus</v-icon
                      >
                      {{ $vuetify.breakpoint.mobile ? "" : $t('button.cron.add') }}
                    </v-btn>
                  </template>
                </TableFullScreenMessage>
              </template>

              <template v-slot:actions="item">
                <v-tooltip
                  transition="custom-tooltip"
                  open-delay="150"
                  bottom
                  z-index="99"
                  offset-overflow
                  nudge-bottom="4px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      icon
                      small
                      @click="showEditModal(item)"
                    >
                      <v-icon>$edit2</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('button.cron.update') }}</span>
                </v-tooltip>
                <v-tooltip
                  transition="custom-tooltip"
                  open-delay="150"
                  bottom
                  z-index="99"
                  offset-overflow
                  nudge-bottom="4px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      icon
                      small
                      @click="showDeleteModal(item)"
                    >
                      <v-icon>$thrash</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('button.cron.delete') }}</span>
                </v-tooltip>
              </template>

              <template v-slot:mobile="{ item, headers }">
                <div class="mobile-table-item__row">
                  <div
                    class="mobile-table-item__header"
                    @click="$emit('update:sortBy', headers[0])"
                  >
                    {{ $t('table.header.cronExpression') }}
                  </div>
                  <div class="mobile-table-item__value">
                    <span class="bold p-3">{{ item.cron_expression }}</span>
                  </div>
                </div>
                <div class="mobile-table-item__row">
                  <div
                    class="mobile-table-item__header"
                    @click="$emit('update:sortBy', headers[1])"
                  >
                    {{ $t('table.header.description') }}
                  </div>
                  <div class="mobile-table-item__value">
                    <span class="p-3">{{ item.description }}</span>
                  </div>
                </div>
                <div class="mobile-table-item__row">
                  <div
                    class="mobile-table-item__header"
                    @click="$emit('update:sortBy', headers[2])"
                  >
                    {{ $t('table.header.command') }}
                  </div>
                  <div class="mobile-table-item__value">
                    <span class="p-3">{{ item.command }}</span>
                  </div>
                </div>
              </template>
            </hosting-table>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      transition="custom-dialog-transition"
      :persistent="modalOptions.persistent"
      v-model="modalOptions.open"
      ref="dialog"
    >
      <div
        class="card-overlay"
        v-if="!modalOptions.persistent"
        @click="modalOptions.open = !modalOptions.open"
      />
      <div class="card-overlay" v-else @click="$refs.dialog.animateClick()" />
      <basic-modal
        style="width: 560px"
        :modalOptions="modalOptions"
        @modal-close="modalClose"
        :key="modalRender"
        ref="modal"
      />
    </v-dialog>
  </div>
</template>

<script>
import Api from "../../apis/Api";

import BasicModal from "@/components/modal/BasicModal";
import DataIteratorFilterControls from "../../components/dataIterator/DataIteratorFilterControls.vue";
import HostingTable from "../../components/dataIterator/tables/HostingTable.vue";
import TableFullScreenMessage from "../../components/dataIterator/tables/TableFullScreenMessage.vue";
import HostingCronJobsIllustration from "../../components/illustrations/hosting/hosting-cron-jobs-illustration.vue";

import ActionModalMixin from "@/mixins/ActionModalMixin";
import DataIteratorPageMixin from "../../mixins/DataIteratorPageMixin";
import CustomTablePageMixin from "../../mixins/CustomTablePageMixin";

import cronstrue from "cronstrue";
import cronparser from "cron-parser";
import ActiveServerAccountSelector from "@/components/ActiveServerAccountSelector.vue";

export default {
  components: {
    ActiveServerAccountSelector,
    BasicModal,
    DataIteratorFilterControls,
    HostingTable,
    TableFullScreenMessage,
    HostingCronJobsIllustration,
  },
  mixins: [ActionModalMixin, DataIteratorPageMixin, CustomTablePageMixin],

  data() {
    return {
      isServerProvided: false,
      loading: true,
      addButtonLoading: false,
      items: [],
      searchTerm: "",
      sortDesc: true,
      page: 1,
      itemsPerPage: 8,
      headers: [
        {
          text: 'cronExpression',
          value: "cron_expression",
          sortable: true,
        },
        {
          text: 'description',
          value: "description",
          sortable: true,
        },
        {
          text: 'command',
          value: "command",
          sortable: true,
        },
        {
          text: 'actions',
          value: "actions",
        },
      ],
      modalRender: 0,
      modalOptions: { open: false },
      highlightItem: {},
      serverAccount: null
    };
  },
  computed: {
    itemsTotal: function () {
      return this.items.length;
    },
  },
  props: {
    service: Object
  },
  watch: {
    highlightItem: function (newValue) {
      if (!newValue) return;
      setTimeout(() => {
        this.highlightItem = {};
      }, 1000);
    },
    service: function () {
      this.serverAccount = this.service.server_accounts[0];
    },
    serverAccount: function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.reloadData();
      }
    },
    "modalOptions.open": function (value) {
      value
        ? this.$store.dispatch("lockBodyScroll")
        : this.$store.dispatch("unlockBodyScroll");
    },
  },

  methods: {
    cronForHumans(expr) {
      try {
        cronparser.parseExpression(expr);
        return cronstrue.toString(expr);
      } catch (error) {
        return this.$t('notification.hosting.cron.invalidExpression');
      }
    },
    formFields(item = null) {
      let translation = (values) => {
        return (
          this.$t('form.label.frequency') + ": " +
          this.cronForHumans(
            `${values.minute} ${values.hour} ${values.day_of_month} ${values.month} ${values.day_of_week}`
          )
        );
      };
      let updateTranslation = (values) => {
        for (const field of this.modalOptions.formFields) {
          if (field.name == "expression") {
            field.description = translation(values);
            break;
          }
        }
      };
      return [
        {
          label: this.$t('form.label.commonSettings'),
          name: "common_settings",
          tooltip: "tooltip.add_cron_job.common_settings",
          type: "select",
          options: [
            {
              label: this.$t('form.option.custom'),
              value: "custom",
            },
            {
              label: this.$t('form.option.cron.everyMinute'),
              value: "every_minute",
            },
            {
              label: this.$t('form.option.cron.everyFiveMinutes'),
              value: "every_5_minutes",
            },
            {
              label: this.$t('form.option.cron.twiceAnHour'),
              value: "twice_an_hour",
            },
            {
              label: this.$t('form.option.cron.onceAnHour'),
              value: "once_an_hour",
            },
            {
              label: this.$t('form.option.cron.twiceADay'),
              value: "twice_a_day",
            },
            {
              label: this.$t('form.option.cron.onceADay'),
              value: "once_a_day",
            },
            {
              label: this.$t('form.option.cron.oneAWeek'),
              value: "once_a_week",
            },
            {
              label: this.$t('form.option.cron.twiceAMonth'),
              value: "1st_and_15th",
            },
            {
              label: this.$t('form.option.cron.onceAMonth'),
              value: "once_a_month",
            },
            {
              label: this.$t('form.option.cron.onceAYear'),
              value: "once_a_year",
            },
          ],
          onChange: (values) => {
            switch (values.common_settings) {
              case "every_minute":
                this.$refs.modal.setValue("minute", "*");
                this.$refs.modal.setValue("hour", "*");
                this.$refs.modal.setValue("day_of_month", "*");
                this.$refs.modal.setValue("month", "*");
                this.$refs.modal.setValue("day_of_week", "*");
                break;
              case "every_5_minutes":
                this.$refs.modal.setValue("minute", "*/5");
                this.$refs.modal.setValue("hour", "*");
                this.$refs.modal.setValue("day_of_month", "*");
                this.$refs.modal.setValue("month", "*");
                this.$refs.modal.setValue("day_of_week", "*");
                break;
              case "twice_an_hour":
                this.$refs.modal.setValue("minute", "0,30");
                this.$refs.modal.setValue("hour", "*");
                this.$refs.modal.setValue("day_of_month", "*");
                this.$refs.modal.setValue("month", "*");
                this.$refs.modal.setValue("day_of_week", "*");
                break;
              case "once_an_hour":
                this.$refs.modal.setValue("minute", "0");
                this.$refs.modal.setValue("hour", "*");
                this.$refs.modal.setValue("day_of_month", "*");
                this.$refs.modal.setValue("month", "*");
                this.$refs.modal.setValue("day_of_week", "*");
                break;
              case "twice_a_day":
                this.$refs.modal.setValue("minute", "0");
                this.$refs.modal.setValue("hour", "0,12");
                this.$refs.modal.setValue("day_of_month", "*");
                this.$refs.modal.setValue("month", "*");
                this.$refs.modal.setValue("day_of_week", "*");
                break;
              case "once_a_day":
                this.$refs.modal.setValue("minute", "0");
                this.$refs.modal.setValue("hour", "0");
                this.$refs.modal.setValue("day_of_month", "*");
                this.$refs.modal.setValue("month", "*");
                this.$refs.modal.setValue("day_of_week", "*");
                break;
              case "once_a_week":
                this.$refs.modal.setValue("minute", "0");
                this.$refs.modal.setValue("hour", "0");
                this.$refs.modal.setValue("day_of_month", "*");
                this.$refs.modal.setValue("month", "*");
                this.$refs.modal.setValue("day_of_week", "0");
                break;
              case "1st_and_15th":
                this.$refs.modal.setValue("minute", "0");
                this.$refs.modal.setValue("hour", "0");
                this.$refs.modal.setValue("day_of_month", "1,15");
                this.$refs.modal.setValue("month", "*");
                this.$refs.modal.setValue("day_of_week", "*");
                break;
              case "once_a_month":
                this.$refs.modal.setValue("minute", "0");
                this.$refs.modal.setValue("hour", "0");
                this.$refs.modal.setValue("day_of_month", "1");
                this.$refs.modal.setValue("month", "*");
                this.$refs.modal.setValue("day_of_week", "*");
                break;
              case "once_a_year":
                this.$refs.modal.setValue("minute", "0");
                this.$refs.modal.setValue("hour", "0");
                this.$refs.modal.setValue("day_of_month", "1");
                this.$refs.modal.setValue("month", "1");
                this.$refs.modal.setValue("day_of_week", "*");
                break;
            }
            updateTranslation(values);
          },
        },
        {
          label: this.$t('form.label.cronExpression'),
          name: "expression",
          tooltip: "tooltip.add_cron_job.cron_expression",
          type: "multiText",
          inputs: [
            { name: "minute" },
            { name: "hour" },
            { name: "day_of_month" },
            { name: "month" },
            { name: "day_of_week" },
          ],
          onInput: (values) => {
            updateTranslation(values);
          },
          description: item ? translation(item) : "",
        },
        {
          label: this.$t('form.label.command'),
          name: "command",
          tooltip: "tooltip.add_cron_job.command",
          rules: [(v) => !!v || this.$t('form.validation.required', {field: this.$t('form.label.command')})],
          type: "text",
        },
      ];
    },
    showAddModal() {
      this.resetModal();
      this.modalOptions.title = this.$t('heading.hosting.cron.modal.add.title');
      this.modalOptions.icon = "$plus";
      this.modalOptions.buttons.unshift({
        label: this.$t('button.cron.add'),
        color: "primary",
        onclick: (modal) => {
          modal.$refs.form.validate() && this.addCronJob(modal.formValues);
        },
      });
      let item = {
        common_settings: "every_5_minutes",
        minute: "*/5",
        hour: "*",
        day_of_month: "*",
        month: "*",
        day_of_week: "*",
      };
      this.modalOptions.formFields = this.formFields(item);
      this.modalOptions.item = item;
      this.modalOptions.open = true;
    },
    addCronJob(formData) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      this.modalOptions.submittingSuccess = "";
      this.modalOptions.submittingError = "";

      Api.post(`/server-accounts/${this.serverAccount.id}/cron-jobs`, formData)
        .then(() => {
          this.modalOptions.submittingSuccess = this.$t('notification.hosting.cron.add.success');
          this.highlightItem = formData;
          this.reloadData();

          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.modalOptions.submittingSuccess,
          });
          this.modalOptions.open = false;
        })
        .catch((error) => {
          this.modalOptions.submittingError = Api.getErrorMessage(error);

          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: this.modalOptions.submittingError,
          });
        })
        .finally(() => {
          this.modalOptions.persistent = false;
          this.modalOptions.submitting = false;
        });
    },
    showEditModal(item) {
      this.resetModal();
      item.common_settings = "custom";
      this.modalOptions.item = item;
      this.modalOptions.title = this.$t('heading.hosting.cron.modal.update.title')
      this.modalOptions.icon = "$edit";
      this.modalOptions.buttons.unshift({
        label: this.$t('button.update'),
        color: "primary",
        onclick: (modal) => {
          modal.$refs.form.validate() &&
            this.updateCronJob(item, modal.formValues);
        },
      });
      this.modalOptions.formFields = this.formFields(item);
      this.modalOptions.open = true;
    },
    updateCronJob(item, formData) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      this.modalOptions.submittingSuccess = "";
      this.modalOptions.submittingError = "";

      Api.put(
        `/server-accounts/${this.serverAccount.id}/cron-jobs/${item.line}`,
        formData
      )
        .then(() => {
          this.modalOptions.submittingSuccess = this.$t('notification.hosting.cron.update.success');
          this.highlightItem = formData;
          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.modalOptions.submittingSuccess,
          });
          this.reloadData();
          this.modalOptions.open = false;
        })
        .catch((error) => {
          this.modalOptions.submittingError = Api.getErrorMessage(error);

          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: this.modalOptions.submittingError,
          });
        })
        .finally(() => {
          this.modalOptions.persistent = false;
          this.modalOptions.submitting = false;
        });
    },
    showDeleteModal(item) {
      this.resetModal();
      this.modalOptions.item = item;
      this.modalOptions.title = this.$t('heading.hosting.cron.modal.delete.title')
      this.modalOptions.color = "error";
      this.modalOptions.icon = "$alertwarning";

      this.modalOptions.formFields = [
        {
          message: `<b>${this.$t('message.confirmAction')}</b>`,
          label: this.$t('form.confirmDelete.cron'),
          name: "confirm",
          type: "checkbox",
          required: true,
        },
      ];

      this.modalOptions.buttons.unshift({
        label: this.$t('button.delete'),
        color: "error",
        onclick: (modal) => {
          modal.$refs.form.validate() && this.deleteCronJob(item);
        },
      });
      this.modalOptions.open = true;
    },
    deleteCronJob(item) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      this.modalOptions.submittingSuccess = "";
      this.modalOptions.submittingError = "";

      Api.delete(
        `/server-accounts/${this.serverAccount.id}/cron-jobs/${item.line}`
      )
        .then(() => {
          this.modalOptions.submittingSuccess = this.$t('notification.hosting.cron.delete.success');
          this.reloadData();

          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.modalOptions.submittingSuccess,
          });
          this.modalOptions.open = false;
        })
        .catch((error) => {
          this.modalOptions.submittingError = Api.getErrorMessage(error);

          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: this.modalOptions.submittingError,
          });
        })
        .finally(() => {
          this.modalOptions.persistent = false;
          this.modalOptions.submitting = false;
        });
    },
    reloadData() {
      if (this.serverAccount.diagnostic_mode) {
        this.$store.dispatch("addAlert", {
          success: false,
          errorMessage: this.$t("message.hostingAccountInDiagnosticMode"),
        });
        this.loading = false;
        return;
      }
      this.loading = true;
      Api.get(`/server-accounts/${this.serverAccount.id}/cron-jobs`)
        .then((response) => {
          this.items = response.data.data.map((i) => {
            i.cron_expression = `${i.minute} ${i.hour} ${i.day_of_month} ${i.month} ${i.day_of_week}`;
            i.description = this.cronForHumans(i.cron_expression);
            return i;
          });
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    itemClass(item) {
      if (
        this.highlightItem.command &&
        item.command == this.highlightItem.command &&
        item.minute == this.highlightItem.minute &&
        item.hour == this.highlightItem.hour &&
        item.day_of_month == this.highlightItem.day_of_month &&
        item.month == this.highlightItem.month &&
        item.day_of_week == this.highlightItem.day_of_week
      ) {
        return "highlight";
      }
    },
  },
  mounted() {
    if (this.service) {
      this.serverAccount = this.service.server_accounts[0];
    }
  },
};
</script>

<style scoped lang="scss">
.title-row {
  @media (min-width: 1401px) {
    height: 70px;
  }
}
</style>
